<template>
  <div>
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      title="Terms and Conditions"
    />
    <span
      class="ma-0 staticPages"
    >
      <v-card flat>
        <p>The NSW Department of Education conducts surveillance and monitoring of its email and online systems to ensure the ongoing confidentiality, integrity and availability of business and education systems. Monitoring will be conducted in accordance with NSW Workplace Surveillance Act 2005 and relevant department policies including the&nbsp;<a
          href="#associateservices"
          id="associateservices"
          @click="openWindow('https://education.nsw.gov.au/policy-library/policies/communication-devices-and-associated-services-policy',5)"
        >Communication Devices and Associated Services Policy</a>&nbsp;.</p>
        <p>Using the department&rsquo;s internet, online and email systems to seek out, access or send any material of an offensive, obscene, pornographic, threatening, abusive, unlawful or defamatory nature may result in disciplinary or legal action. Use of the department's internet, online and email systems requires compliance with the following policies and procedures:</p>
        <ul tabindex="0">
          <li><a
            href="#conduct"
            id="conduct"
            @click="openWindow('https://education.nsw.gov.au/policy-library/policies/code-of-conduct-policy',5)"
          >Code of Conduct Policy</a></li>
          <li><a
            href="#communication"
            id="communication"
            @click="openWindow('https://education.nsw.gov.au/policy-library/policies/online-communication-services-acceptable-usage-for-school-students',5)"
          >Online Communication Services: Acceptable usage for school students</a></li>
          <li><a
            href="#devices"
            id="devices"
            @click="openWindow('https://education.nsw.gov.au/policy-library/policies/communication-devices-and-associated-services-policy',5)"
          >Communication Devices and Associated Services Policy</a></li>
          <li><a
            href="#enterprise"
            id="enterprise"
            @click="openWindow('https://education.nsw.gov.au/policy-library/policies/enterprise-data-policy',5)"
          >Enterprise Data Policy</a></li>
          <li><a
            href="#socialmedia"
            id="socialmedia"
            @click="openWindow('https://education.nsw.gov.au/policy-library/policies/social-media-policy',5)"
          >Social Media Policy</a>.</li>
        </ul>
        <p>Failure to comply with these policies may also result in disciplinary or legal action.</p>
        <p>Personal information will only be accessed, collected and used in accordance with the NSW Privacy and Personal information Protection Act 1998 and related departmental policies (copies of related departmental policies can be found on the department's&nbsp;<a
          href="#"
          @click="openWindow('https://education.nsw.gov.au/policy-library',5)"
        >policy library</a>). Failure to comply with these requirements may result in disciplinary or legal action.</p>
        <p>Note:&nbsp;Replying to unsolicited advertising email material (known as spam) or attempting to remove your email address from any mailing list may result in more unsolicited email. Further information is available on the department's&nbsp;<a
          href="#"
          @click="openWindow('https://education.nsw.gov.au/technology/getting-help/self-help/email-phishing-and-spam',5)"
        >Email phishing and spam (staff only)</a>&nbsp;section.</p>
        <p>If you receive offensive material, notify your principal/manager who will assess the material and take appropriate action.</p>
        <p>If you receive unsolicited advertising material from an unknown source, delete it, preferably without opening it.</p>
        <p>If you receive other inappropriate material from a known source, advise the sender that you do not wish to receive that type of material.</p>
      </v-card>
    </span>
  </div>
</template>
<script>
  import appBarMobile from "@/components/appBarMobile";
  export default {
    components: {
      appBarMobile,
    },
    created(){
      window.scrollTo(0,0);
    },
    methods:{
      openWindow(url,index){
        //eslint-disable-next-line
        NativeJS.openWindow(url,index);
      }
    }
  }
</script>

<style scoped>
::v-deep .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle{
  color:#121212;
}
</style>